import { Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {environment as env} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  fcm_token_new: string = '';
  constructor(private http: HttpClient) { }

  login(password: string, fcmToken: string, username:string): Observable<any>{
    const params = {
      password,
      fcmToken,
      username
    }
    return this.http.post<any>(`${env.url}loginV2`, params);
  }

  getOTP(username: string): Observable<any>{
    const params = {
      username
    }
    return this.http.post<any>(`${env.url}getOTP`, params);
  }

  logout(): Observable<any>{
    return this.http.post<any>(`${env.url}logoutV2`, {});
  }

  getActiveStatus(): Observable<any>{
    return this.http.post<any>(`${env.url}getactivev2`, {});
  }

  postActiveStatus(status): Observable<any>{
    return this.http.post<any>(`${env.url}setactivev2`, {status});
  }
  
}
