import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';

import { Observable, throwError, from } from 'rxjs';
import { retry, catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

import { AuthService } from '@services/auth.service';
import { StorageService } from './storage.service';
import { DebugService } from './debug.service';
import { Plugins } from '@capacitor/core';
const { Network } = Plugins;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  constructor(
    public authService: AuthService,
    private router: Router,
    private alertCtrl: AlertController,
    private storage: StorageService,
    private debugService: DebugService
  ) {}
   intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
   
    return from(this.storage.getString('userToken')).pipe(
      switchMap(token => {
        this.debugService.log('Interceptor Service Token Response =>',token);
        token=token.value;
      
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        return next.handle(request).pipe(
          catchError( (error: HttpErrorResponse) => {
            
            
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            if (
              error.status === 400 &&
              error.error.error === 'token_not_provided'
            ) {
              this.storage.clear();
              //this.authService.changeLogin(false);
            }
            if (error.status == 401) {
              Network.getStatus().then(x=>{
                if(x.connected == true){
                    alert("Multiple Logins Prohibited")
                    this.router.navigateByUrl('/login');
                    this.storage.clear();   
                 
                }
              }
              );
             
            }

            if (error.status == 402) {
              Network.getStatus().then(x=>{
                if(x.connected == true){
                    alert("Wrong OTP")
                    this.router.navigateByUrl('/login2');
                    this.storage.clear();   
                 
                }
              }
              );
             
            }
            if (error.status == 406) {
              Network.getStatus().then(x=>{
                if(x.connected == true){
                    alert("OTP Timeout");      
                    this.router.navigateByUrl('/login');
                    this.storage.clear();   
                 
                }
              }
              );
            }
            if (error.status == 500) {
              this.contactSupportAlert().then(alert500 => {
                alert500.present();
              });
            }
            return throwError(errorMessage);
          })
        );
      })
    );
  }
  contactSupportAlert() {
    return this.alertCtrl.create({
      header: 'Error',
      message: 'Please contact callcenter',
      buttons: [
        {
          text: 'OK!',
          role: 'cancel',
        },
      ],
    });
  }
}
