import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { StorageService } from '@services/storage.service';
import { Observable, pipe } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router,private storage: StorageService) {}
  canActivate(): boolean {
    this.storage.getString('userToken').then(
      storageRes => {
      console.log('Response from storage => ', storageRes);
      } 
    )
    return true;
    
    /* if (!this.authService.gettoken()) {
      this.router.navigateByUrl("/login");
    }
    return this.authService.gettoken(); */
  }
  
}
