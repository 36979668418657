import { Injectable } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core';

import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { AlertController, NavController } from '@ionic/angular';
import { AuthService } from './auth.service';



const { PushNotifications, Storage, Modals } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class FcmService {
  
foodReady:any;
  constructor(private nativeAudio: NativeAudio,private vibration: Vibration, private authService:AuthService,private navController: NavController,public modalController: AlertController) { }
  
  //getItem() {
   // return Storage.get({ key: 'token' });
 //}

  public initPush(){
     this.registerPush();
  }

/*   pushArea(){
    this.push.hasPermission()
  .then((res: any) => {

    if (res.isEnabled) {
      console.log('We have permission to send push notifications');
    } else {
      console.log('We do not have permission to send push notifications');
    }

  });
  

  this.push.createChannel({
    id: "farhanIsAwesome",
    description: "My first test channel",
    // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
    importance: 3,
    //badge is used to if badge appears on the app icon see https://developer.android.com/reference/android/app/NotificationChannel.html#setShowBadge(boolean).
    //false = no badge on app icon.
    //true = badge on app icon
    badge: false
   }).then(() => console.log('Channel created'));
 
   this.push.listChannels().then((channels) => console.log('List of channels', channels))

   const options: PushOptions = {
    android: {},
    ios: {
        alert: 'true',
        badge: true,
        sound: 'false'
    },
    windows: {},
    browser: {
        pushServiceURL: 'http://push.api.phonegap.com/v1/push'
    }
 }
 
 const pushObject: PushObject = this.push.init(options);
 
 
 pushObject.on('notification').subscribe((notification: any) => {
   console.log('Received a notification', notification);
   this.nativeAudio.play('uniqueId1').then(x => {
    console.log("PLAY SUCCESS!");
    console.log(x);
  }).catch(err =>{
    console.log("PLAY FAIL");
    console.log(err);
  });
  });
 
 pushObject.on('registration').subscribe((registration: any) => {console.log('Device registered', registration);
alert('YES PUSH');});
 
 pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
 
 
  } */

  private registerPush(){
    PushNotifications.requestPermission().then( result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });
    
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        // alert('FCM registeration sucess');
        Storage.set({
          key: 'token',
          value: token.value
        })
        this.authService.fcm_token_new = token.value;
        console.log(token);
      });      
    
    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );
    
// Some issue with our setup and push will not work
PushNotifications.createChannel({
  id: "Order", // required
  name: "Order", // required
  description: "Very urgent message alert",
  importance: 5, // https://developer.android.com/guide/topics/ui/notifiers/notifications#importance
  visibility: 1, // https://developer.android.com/training/notify-user/build-notification#lockscreenNotification
  sound: "ring", // In the "alert_sound" example, the file should located as resources/raw/alert_sound.mp3
  lights: true, // enable lights for notifications
  vibration: true // enable vibration for notifications
},
);
PushNotifications.createChannel({
  id: "Orderb", // required
  name: "Orderb", // required
  description: "Very urgent message alert",
  importance: 5, // https://developer.android.com/guide/topics/ui/notifiers/notifications#importance
  visibility: 1, // https://developer.android.com/training/notify-user/build-notification#lockscreenNotification
  sound: "ringb", // In the "alert_sound" example, the file should located as resources/raw/alert_sound.mp3
  lights: true, // enable lights for notifications
  vibration: true // enable vibration for notifications
},
);
PushNotifications.createChannel({
  id: "Orderc", // required
  name: "Orderc", // required
  description: "Very urgent message alert",
  importance: 5, // https://developer.android.com/guide/topics/ui/notifiers/notifications#importance
  visibility: 1, // https://developer.android.com/training/notify-user/build-notification#lockscreenNotification
  sound: "ringc", // In the "alert_sound" example, the file should located as resources/raw/alert_sound.mp3
  lights: true, // enable lights for notifications
  vibration: true // enable vibration for notifications
},
);
/* this.backgroundMode.enable();
this.backgroundMode.on('activate').subscribe(x => {
  PushNotifications.addListener('pushNotificationReceived',
  (notification: PushNotification) => {
    alert('Push received: ' + JSON.stringify(notification));
    this.nativeAudio.play('uniqueId1').then(x => {
     
      console.log("PLAY SUCCESS!");
      console.log(x);
    }).catch(err =>{
     
      console.log("PLAY FAIL");
      console.log(err);
    });
  }
);
}); */

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
    /*async*/ (notification: PushNotification) => {
      /* alert('Push received: ' + JSON.stringify(notification)); */
      
      var audio1 = new Audio('assets/ring.mp3');
      audio1.play();
      notification.click_action="FCM_PLUGIN_ACTIVITY";
      notification.id="Order";
      Modals.alert({
        title: notification.title,
        message: notification.body })
     
      this.vibration.vibrate([2000,1000,2000])
/*        var x =notification.data.customMsg ;
      if(x==1)
      {
        this.presentModal().then(pickElement => {
          pickElement.present();
        });
      }  */
    }
  );
    /* this.backgroundMode.enable();

    this.backgroundMode.on("activate").subscribe(()=>{
      
    }); */
    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
       /*   alert('Push action performed: ' + JSON.stringify(notification));
         var x =notification.notification.data.customMsg ;
        if(x==1)
        {
        
          
        }  */
      }
    );
/*
    this.nativeAudio.preloadSimple('uniqueId1', 'assets/ring.mp3').then(x => {
      // alert("LOAD SUCCESS!");
      console.log("LOAD SUCCESS!");
      console.log(x);
    }).catch(err =>{
      // alert("LOAD FAIL!");
      // alert(err);
      console.log("LOAD FAIL");
      console.log(err);
    });
  */}
}
