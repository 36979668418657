import { Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {environment as env} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  constructor(private http: HttpClient) { }


  log(message: string, data: any){
    if(!env.production){
    console.log(message + ' => ',data);
    }
  }
}
