import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Driver } from '@models/users';

import {environment as env} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private http: HttpClient) { }
  

  getOrdersDataAPI(): Observable<any>{
    return this.http.get<any>(`${env.url}orders/loadV2`);
  }


  postPickUpAPI(id: number): Observable<any>{
    return this.http.post<any>(`${env.url}orders/picked`,{id});
  }

  postDeliveredAPI(id: number): Observable<any>{
    return this.http.post<any>(`${env.url}orders/delivered`,{id});
  }
  updatePaymentAPI(orderId: number,paymentIssue:number): Observable<any>{
    return this.http.post<any>(`${env.url}updatePaymentIssue`,{orderId,paymentIssue});
  }

  benefitOnlinePaymentAPI(order_id: number): Observable<any>{
    return this.http.post<any>( `https://machine.ahlanapp.com/api/benefit_pay/generate_link`,{order_id});
  }
}

