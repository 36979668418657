import { Component, OnInit } from '@angular/core';
import { Plugins,AppState } from '@capacitor/core';
import { AlertController, MenuController, NavController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FcmService } from './_services/fcm.service';
import { LoaderService } from '@services/loader.service';
import { AuthService } from '@services/auth.service';
import { StorageService } from '@services/storage.service';
import { DebugService } from '@services/debug.service';
import { UserService } from '@services/user.service';
import { Driver } from '@models/users';
import { Route, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import 'firebase/firestore';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { BehaviorSubject, Observable } from 'rxjs';
//import { Network } from '@ionic-native/network/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { Toast } from '@ionic-native/toast/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Order } from '@models/order';
import moment from 'moment';
import { OrderService } from '@services/order.service';
import { SettingService } from '@services/setting.service';
import { DriverHelpService } from "@services/driver-help.service";
export enum ConnectionStatus {
  Online,
  Offline
}

const { App } = Plugins;
const { Network } = Plugins;
const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  private Onlinestatus: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);
  currentAppVersion:String ;
  latestAppVersion:String ;
  status;
  driver_type;
  public selectedIndex = 0;
  userData: Driver;
  phoneNum:any;
    activeOrders: Order[] = [];
  inactiveOrders: Order[] = [];
  driver:any;
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home-outline'
    },
    {
      title: 'Dashboard',
      url: '/dashboardv2',
      icon: 'bar-chart-outline'
    },
/*     {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'bar-chart-outline'
    }, */
    {
      title: 'Schedule',
      url: '/time-table',
      icon: 'time-outline'
    }
    /* {
      title: 'logout',
      url: '/login',
      icon: 'log-out-outline'
    },  */
  ];
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private fcmService: FcmService,
    private menu: MenuController,
    private loaderService: LoaderService,
    private authService: AuthService,
    private storage: StorageService,
    private debugService: DebugService,
    private userService: UserService,
    private route: Router,
    private navController: NavController,
    private appVersion: AppVersion,
    private plt: Platform,
    //private network :Network,
    private toastController:ToastController,
    private alertController: AlertController,
    private androidPermissions:AndroidPermissions,

    private toast:Toast,
    private diagnostic: Diagnostic,
    private menuController: MenuController,
    private settingService: SettingService,
    private orderService: OrderService,
    private driverHelpService: DriverHelpService,
  ) {
    this.initializeApp();
  
  }

  async driverHelp(){
    var name= this.userService.getUserData().name;
    var userPhone= this.userService.getUserData().userPhone;
    var handled=0;
    try{
      let status = await Network.getStatus();
      if(status.connected == true){
    this.presentAlert();
    this.driverHelpService.getDriverHelpAPI(name,userPhone,handled).subscribe();
     }
     else
     {
       alert("Connect to Internet and Try Again.");
     }
    }
    catch(err)
    {
     this.InternetAlert();
    }
   }


   HPAlert(){
    this.helpAlert().then(pickElement => {
      pickElement.present();
    });
   }  

   async helpAlert(){
    {
      return this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Help Button',
        message: 'Do you want to use the Help Button ?',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              //console.log('Not Updated');
            }
          }, {
            text: 'Yes',
            handler: async () => {
             /*  if(this.getCurrentNetworkStatus()==ConnectionStatus.Online){ */
              let status = await Network.getStatus();
              if(status.connected == true){
                this.driverHelp()
              }
              else
              {
                alert("Connect to Internet and Try Again.");
              }
             // console.log('Post to accept the order');
            }
          }
        ]
      });
    }
   }


   async toggleStatus(){

     this.StatusChangeAlert(this.status).then(pickElement => {
      pickElement.present();
    });  
  };

  async StatusChangeAlert(value){
    {
      return this.alertController.create({
        cssClass: 'my-custom-class2',
        header: 'Active Status',
        message: 'Are You Sure ?',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
            this.menuOpen();
            }
          }, {
            text: 'Yes',
            handler: async () => {
            let status = await Network.getStatus();
            if(status.connected == true){
            let postStatus = value;
            this.status = value;
            this.authService.postActiveStatus(postStatus).subscribe();
              }
              else
              {
                alert("Connect to Internet and Try Again.");
              }
            }
          }
        ]
      });
    }
   }

   async contact() {
   try{

    let toast = this.toastController.create({
      message: 'Contact The Call Center: 17434353',
      duration: 10000,
      position: 'bottom'
    });
    toast.then(toast => toast.present());
   }
   catch(err)
   {
console.log(err)
   }
  }


   async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm!',
      message: '<strong>Your request has been sent to the Agents. They will contact you shortly.</strong>!!!',
      buttons: [
       {
          text: 'Okay',
          handler: () => {
            console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }
  public initializeNetworkEvents() {

 /*    this.network.onDisconnect().subscribe(() => {
      if (this.Onlinestatus.getValue() === ConnectionStatus.Online) {
        console.log('WE ARE OFFLINE');
        this.updateNetworkStatus(ConnectionStatus.Offline);
      }
    }); */
  
/*     this.network.onConnect().subscribe(() => {
      if (this.Onlinestatus.getValue() === ConnectionStatus.Offline) {
        console.log('WE ARE ONLINE');
        this.updateNetworkStatus(ConnectionStatus.Online);
      }
    }); */
  }
  private async updateNetworkStatus(status: ConnectionStatus) {
    this.Onlinestatus.next(status);
  
    let connection = status == ConnectionStatus.Offline ? 'Offline' : 'Online';
    let toast = this.toastController.create({
      message: `Your internet connection is ${connection}`,
      duration: 10000,
      position: 'bottom'
    });
    toast.then(toast => toast.present());
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.Onlinestatus.asObservable();
  }
  
  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.Onlinestatus.getValue();
  }

  clickMenuItem(index){
    this.selectedIndex = index;
   /*  if(index == 3){
      this.logout();
    }else{ */
      this.route.navigate([this.appPages[index].url]);
  /*   } */
  }

  initializeApp() {
    this.platform.ready().then(() => {
     if(this.platform.is('cordova'))
     {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      //this.checkGPSPermission();
     }
      this.storage.getString('userToken').then(
        storageRes => {
        //console.log('Response from storage => ', storageRes);
        if (!storageRes.value) {
          this.navController.navigateRoot('/login', {animationDirection: "forward"});
        } 
        else {
           this.authService.getActiveStatus().toPromise().then(res => {
            this.debugService.log('Response from GET STATUS => ',res);
            this.status = res.status;
            this.driver_type = res.driver_type;
            //console.log(this.status);
            //this.route.navigate(["home"]);
            this.navController.navigateRoot('/home', {animationDirection: "forward"});
          }); 
        }  
      } 
      )
       

      //Trigger the push setup
      if (this.platform.is('hybrid')) {
      this.fcmService.initPush();
      }
    });
  }

  setupActiveStatus(){
    this.authService.getActiveStatus().toPromise().then(res => {
      this.debugService.log('Response from GET STATUS => ',res);
      this.status = res.status;
      this.driver_type = res.driver_type;
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

/*     if(this.platform.pause.subscribe())
    {
      if(this.diagnostic.locationMode.LOCATION_OFF)
      {
       alert('Please Allow location permissions')
      }
    }  */
  }

  closeMenu(){
    this.menu.close();
  }

  logout(){
    this.loaderService.loaderWithText('Logging out...').then(loader => {
      loader.present();
        this.authService.logout().toPromise().then(x => {
          loader.dismiss();
          if(x.message === 'success'){
            this.storage.clear().then(()=>{
              this.debugService.log('Storage cleared', {});
              this.route.navigate(['/login']);
              loader.dismiss();
              this.debugService.log('Response from Logout API',x);  
            });
          }else{
             alert('You have pending orders, please complete them or contact call center') 
          }
          
            
        }).catch(err => {
          this.debugService.log('Error from Logout API',err.message);
          loader.dismiss();
          // alert("error occured")
          console.log(err);
        });
       
    });
  }


  menuOpen(){
  //console.log('menu will trigger TRIGGERED');

  this.userData = this.userService.getUserData();
  console.log('userData =>',this.userData);
  this.setupActiveStatus();
  
}


hasNewUpdate(){
  this.appVersion.getVersionNumber().then(version => {
   this.currentAppVersion = version;
 });

}
async InternetAlert() {
  const alert = await this.alertController.create({
    cssClass: 'my-custom-class',
    header: 'Confirm!',
    message: '<strong> Please check your Internet Connection and try again.</strong>!!!',
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          //console.log('Confirm Cancel: blah');
        }
      }, {
        text: 'Okay',
        handler: () => {
          //console.log('Confirm Okay');
        }
      }
    ]
  });

  await alert.present();
}

/* checkGPSPermission() {
  this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
    result => {
      if (result.hasPermission) {

        //If having permission show 'Turn On GPS' dialogue
        this.askToTurnOnGPS();
      } else {

        //If not having permission ask for permission
        this.toast.show(`Please Click Allow all the time`, '5000', 'center').subscribe(
          toast => {
          }
        );
        this.requestGPSPermission();
      }
    },
    err => {
      alert(err);
    }
  );
} */

/* requestGPSPermission() {
  this.locationAccuracy.canRequest().then((canRequest: boolean) => {
    if (canRequest) {
      console.log("4");
    } else {
      //Show 'GPS Permission Request' dialogue
      this.androidPermissions.requestPermissions([ // "android.permission.ACCESS_BACKGROUND_LOCATION" , 
      "android.permission.ACCESS_COARSE_LOCATION",
      this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION])
        .then(
          () => {
            // call method to turn on GPS
            this.askToTurnOnGPS();
          },
          error => {
            //Show alert if user click on 'No Thanks'
            alert('Please allow location permissions '  // error/) 
          }
        );
    }
  });
} */

/* askToTurnOnGPS() {
  this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
    () => {
      
    },
    error => {
    //   alert('Please Allow location permissions ' ); 
      //this.askToTurnOnGPS();
  
  }
  ).catch(()=>{
    this.requestGPSPermission();
  });
} */
}

/* 
public appPages = [
    {
      title: 'Inbox',
      url: '/folder/Inbox',
      icon: 'mail'
    },
    {
      title: 'Outbox',
      url: '/folder/Outbox',
      icon: 'paper-plane'
    },
    {
      title: 'Favorites',
      url: '/folder/Favorites',
      icon: 'heart'
    },
    {
      title: 'Archived',
      url: '/folder/Archived',
      icon: 'archive'
    },
    {
      title: 'Trash',
      url: '/folder/Trash',
      icon: 'trash'
    },
    {
      title: 'Spam',
      url: '/folder/Spam',
      icon: 'warning'
    }
  ];
*/