import { Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Driver } from '@models/users';

import {environment as env} from '../../environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  username: string;
  constructor(private http: HttpClient) { }
  private _userData: Driver;


  getUserDataAPI(): Observable<any>{
    const params = {}
    return this.http.get<any>(`${env.url}userData`);
  }

  setUserData(data: Driver){
   this._userData = data;
  }

  getUserData(){
    return this._userData;
  }

  AppVersion(id: number,currentAppVersion,deviceModel,deviceManufacturer,devicePlatform,OSVersion): Observable<any>{

    return this.http.post<any>(`${env.url}appVersion`,{id,currentAppVersion,deviceModel,deviceManufacturer,devicePlatform,OSVersion});
  }
  setUsername(data) {
    this.username = data;
}

getUsername(){
   return this.username;
}


}

