import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(public loadingController: LoadingController) { 
  }

  loaderWithText(msg:string){
       return this.loadingController.create({
        cssClass: 'my-custom-class',
        message: msg,
      });
  }
}
