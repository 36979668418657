import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment as env} from '../../environments/environment';
import { Observable } from 'rxjs';
import { AlertService } from './alert.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DriverHelpService {

  constructor(private http: HttpClient,private alertController:  AlertController) {
   }

  getDriverHelpAPI(name:String,phone:String,handled): Observable<any>{
  try{


    return this.http.post<any>("https://machine.ahlanapp.com/api/zoom/driverHelp",{name,phone,handled});
  }
  catch(err)
  {
console.log(err);
  } 
  }

  getDriverOrderHelpAPI(order_issue,orderId): Observable<any>{
    try{
      return this.http.post<any>("https://machine.ahlanapp.com/api/zoom/driverOrderHelp",{order_issue,orderId});
    }
    catch(err)
    {
  console.log(err);
    } 
  }

  
  cashoutGenerate(): Observable<any>
  {
    try{
    return this.http.post<any>("https://machine.ahlanapp.com/api/cashout/generate",{});
    }
    catch(err)
    {
     alert("Cashout Error" +err);
    } 
  }

  cashoutDashboard(): Observable<any>
  {
    try{
    return this.http.post<any>("https://machine.ahlanapp.com/api/cashout/dashboard",{});
    }
    catch(err)
    {
     alert("CashoutDashboard Error" +err);
    } 
  }
}
