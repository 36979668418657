import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptor } from '@services/interceptor.service';
import { AuthService } from '@services/auth.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireModule } from 'angularfire2';
import { Toast } from '@ionic-native/toast/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';

import { NativeAudio } from '@ionic-native/native-audio/ngx';

import { FilePath } from '@ionic-native/file-path/ngx';
import { FormsModule } from '@angular/forms';
import { Vibration } from '@ionic-native/vibration/ngx';
import { environment } from 'src/environments/environment';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Device } from '@ionic-native/device/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    NativeAudio,
    FilePath,
    AuthService,
    AppVersion,
    CallNumber,
    Vibration,
    AngularFirestore,
    AndroidPermissions,

    Toast,
    Network,
    Diagnostic,
    Device,
    ScreenOrientation,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
