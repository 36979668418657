import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppVersion } from '@ionic-native/app-version/ngx';

import { Setting } from '@models/setting';
import {environment as env} from '../../environments/environment';
import { AlertController, Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
_settingData: Setting;
currentAppVersion:String ;
latestAppVersion:String ;

constructor(private http: HttpClient, private appVersion: AppVersion, private alertController: AlertController, private platform: Platform) { }

getSettingsDataAPI(): Observable<any>{
  return this.http.get<any>(`${env.url}appSettings`);
}

getShowHelp(): Observable<any>{
  return this.http.get<any>(`${env.url}showHelpButton`);
}


hasNewUpdate(settingString: string): Boolean{
   this.appVersion.getVersionNumber().then(version => {
    this.currentAppVersion = version;
    this.latestAppVersion  = settingString;

    this.currentAppVersion = this.currentAppVersion.replace('.','');
    this.latestAppVersion = this.latestAppVersion.replace('.','');

    let currentVersion = Number(this.currentAppVersion);
    let newVersion = Number(this.latestAppVersion);

    if(currentVersion < newVersion){
      this.updateAlert().then(updatePop => {
        updatePop.present();
      })
    }
  });
return false;
}

/* The function below is only intended to convert a text
   version with 2 decimals to a number  */
/* versionStringToArray(oldString: string){
  let temp: string;
  let indexOfDot = oldString.indexOf('.');
  temp = oldString.substring(0,indexOfDot);
  oldString = oldString.substring(indexOfDot+1);
  indexOfDot = oldString.indexOf('.');
  temp += oldString.substring(0,indexOfDot);
  oldString = oldString.substring(indexOfDot+1);
  indexOfDot = oldString.indexOf('.');
  temp += oldString;
  return +temp;
} */

updateAlert(){
  {
    let updateURL =  this.platform.is('android') ? 'https://play.google.com/store/apps/details?id=com.ahlantechnologies.AhlanDrive&hl=en&gl=US':'https://play.google.com/store/apps/details?id=com.ahlantechnologies.AhlanDrive&hl=en&gl=US';
    return this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'New Update',
      message: 'Please update your device to the latest App version.',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('No to update');
          }
        }, {
          text: 'Yes',
          handler: () => {
            window.open(updateURL, '_system');
          }
        }
      ]
    });
  }
 }

}
