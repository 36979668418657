// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //url: 'http://127.0.0.1:8000/api/zoom/' ,
  //url: 'http://172.20.250.225/api/zoom/' ,
  url: 'https://machine.ahlanapp.com/api/zoom/',
  firebaseConfig : {
    apiKey: "AIzaSyAayEYi_OOoZbOdWJdK-gTRCVXU8U-p-NY",
    authDomain: "ahlan-drive.firebaseapp.com",
    databaseURL: "https://ahlan-drive.firebaseio.com",
    projectId: "ahlan-drive",
    storageBucket: "ahlan-drive.appspot.com",
    messagingSenderId: "115097942154",
    appId: "1:115097942154:web:95e8dabc504d6a46285573",
    measurementId: "G-PLW4WT8024"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
